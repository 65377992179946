import React, { useState, useEffect } from "react";
import { Link , useNavigate} from "react-router-dom";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Mico2 from "../../assets/images/sailor/boar-icon.svg";
import { useContext } from "react";
import NoteContext from "../../context/NoteContext";
import { useSession } from '../../SessionContext';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { config } from "../../util/apiconfig";
import EPCLogo from "../../assets/images/sailor/logo-color.png";

function SideMenu() {
    const obj = useContext(NoteContext);
    const { loggedIn, login } = useSession();
	const navigate = useNavigate();

    const getSessionData=()=>{
        const onBoardingID=localStorage.getItem('onBoardingID');
	    const userType=localStorage.getItem('Type');
        console.log(onBoardingID,userType);
        if(onBoardingID==null && userType!=3){
            localStorage.clear();
            navigate("/");

        }
    }
    useEffect(() => {
        getSessionData();        
    },[])

   
 

    async function logout(){
        let data = await fetch(config.mongo_endpoint+"api/v1/sailorUser/logout", {
			  method: "GET",
			  headers: {
				"Content-type": "application/json",
                "x-access-token" : `${localStorage.getItem('Token')}`
			  } 
			});
			let res = await data.json();
			console.log(res);
			if (res.status === "Success") {
			  obj.setToken('')
			  obj.setName('');
              localStorage.clear();
				navigate("/");

			} else {
				// alert(res.message);

			}
    }

    const HandleDownload = async() => {

        await axios.get(config.mongo_endpoint+"api/v1/sailorUser/downloadfiles/user_"+localStorage.getItem('onBoardingID'), {
            headers: {"Content-type": "application/json",} ,
              responseType: 'blob',
        })
        .then(function (response) {
            console.log("sailor",response);
            const blob = new Blob([response.data], { type: 'application/zip' });
            const blobUrl = URL.createObjectURL(blob);
      
            // Create a temporary link and click it to trigger the download
            const link = document.createElement('a');
            link.href = blobUrl;
            link.download = 'Sailor Documents.zip'; // Set the desired file name
            link.click();
      
            // Clean up the blob URL
            URL.revokeObjectURL(blobUrl);
            toast.success("Documents Downloaded Successfully.....", {
                position: toast.POSITION.TOP_RIGHT,
                theme: "colored",
              });  
        })
        .catch(function (error) {
            console.log(error);
        });

    }
    const [url,setUrl] = useState('');
    const checkUrl = () => {
        if(loggedIn){
            setUrl('/Sailordashboard');
        } else{
            setUrl('/');
        }
    }

    useEffect(() => {
        checkUrl();
    },[])


    return (
        <>

            <div class="sidebar shadow">

                <div class="det-bar">
                    <div class="main-title" style={{textAlign: "center"}}>
                        <Link to={url}onClick={checkUrl}>
                            {/* <img src={ELogo} className="img-fluid light-logo" alt="" width="70" />
                            <img src={ELogo1} className="img-fluid logo-txt ml-10" alt="" width="100" height="93" /> */}
                             <img src={EPCLogo} className="img-fluid light-logo" alt="" width="150px" />
                        </Link>
                    </div>


                    <ul>
                        <li className="active">
                        <Link to="/Sailordashboard" class="nav-link" >
                           
                            <img src={Mico1} alt="" className="m-ic" />
                            <span>Dashboard</span>
                        </Link>
                        </li>
                       
                        <li> 
                        
                            <Link to="/viewapplication" class="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>View Application</span>
                            </Link>

                        </li>
                      
                        <li>
                            <Link to="/Updatedetails" class="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Update Details</span>
                            </Link> 
                        </li>
                        <li> <Link to="/Sailorhistory" class="nav-link" >
                            <img src={Mico2} alt="" className="m-ic" />
                            
                            <span>Sailor History</span>
                            </Link>
                        </li>
                        <li> <a class="nav-link collapsed" href="#demo" onClick={()=>{HandleDownload()}} data-bs-toggle="collapse" data-target="#demo">
                            <img src={Mico2} alt="" className="m-ic" />

                            <span>Download Document</span>
                        </a></li>

                        <li> <Link to="/" class="nav-link" onClick={()=>{logout()}} >
                            <img src={Mico2} alt="" className="m-ic" />
                            
                            <span>Log Out</span>
                            </Link>
                        </li>
                    </ul>
                </div>
                <ToastContainer />
            </div>

        </>
    );
}

export default SideMenu;