import React, { useState, useEffect , useContext } from "react";
import { Link , useNavigate} from "react-router-dom";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Mico1 from "../../assets/images/sailor/dash-icon.svg";
import Mico2 from "../../assets/images/sailor/boar-icon.svg";
import NoteContext from "../../context/NoteContext";
import { config } from "../../util/apiconfig";
import EPCLogo from "../../assets/images/sailor/logo-color.png";

function AgentMenu() {
    const obj = useContext(NoteContext);
	const navigate = useNavigate();

    const getSessionData=()=>{
        const agentToken=localStorage.getItem('AgentToken');
	    const userType=localStorage.getItem('Type');
        if((agentToken==""|| agentToken==null) && (userType!="Admin" ||userType!="Agent")){
            localStorage.clear();
            navigate("/Manninglogin");

        }
    }
    useEffect(() => {
        getSessionData();        
    },[])



    // useEffect(()=>{
       
    //     if(!localStorage.getItem('AgentToken')){
    //         navigate('/Manninglogin')
    //     }
    // },[])

    async function logout(){
        let data = await fetch(config.mongo_endpoint+"api/v1/manningAgent/logOut", {
			  method: "GET",
			  headers: {
				"Content-type": "application/json",
                "x-access-token" : `${obj.token}`
			  } 
			});
			let res = await data.json();
            localStorage.clear();
			if (res.status === "Success") {
			  obj.setToken('')
			  obj.setName('');
              localStorage.clear();
				navigate("/Manninglogin");

			} else {
				alert(res.message);

			}
    }
  
    return (
        <>

            <div className="sidebar shadow">

                <div className="det-bar">
                    <div className="main-title" style={{textAlign: "center"}}>
                        <Link to="/">
                            {/* <img src={ELogo} className="img-fluid light-logo" alt="" width="70" />
                            <img src={ELogo1} className="img-fluid logo-txt ml-10" alt="" width="100" height="93" /> */}
                            <img src={EPCLogo} className="img-fluid light-logo" alt="" width="150px"/>
                        </Link>
                    </div>


                    <ul>
                        <li className="active">
                            <Link to="/Manningagentdashboard" className="nav-link" >
                                <img src={Mico1} alt="" className="m-ic" />
                                <span>Dashboard</span>
                            </Link>
                        </li>

                        <li> 
                            <Link to="/Pendingonboarding" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Pending onboarding</span>
                            </Link>
                        </li>
                       
                        <li> 
                            <Link to="/Approvedonboarding" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Approved onboarding</span>
                            </Link>
                        </li>

                        

                        {obj.type==='Admin' && <li> 
                            <Link to="/ManageAgent" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Manage Agent</span>
                            </Link>
                        </li>}
        
                        {/* <li> 
                            <Link to="/ManageAgent" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Manage Agent</span>
                            </Link>
                        </li> */}

<li> 
                            <Link to="/ManageCompany" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Manage Company</span>
                                </Link>
                        </li>

                        <li> 
                            <Link to="/ApplicationConfig" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Application Config</span>
                                </Link>
                        </li>
 <li> 
                            <Link to="/ManageForms" className="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" /><span>Manage Forms</span>
                            </Link>
                        </li>

                        <li> 
                            <Link to="/Manninglogin" class="nav-link" >
                                <img src={Mico2} alt="" className="m-ic" onClick={()=>{logout()}} /><span>Log Out</span>
                            </Link>
                        </li>
                        

                    </ul>
                </div>

            </div>

        </>
    );
}

export default AgentMenu;