import React, { useState, useEffect, useContext } from "react";
import Topnav from "../../common/topNav";
import { Link, useNavigate } from "react-router-dom";
// import Footer from "../../common/footer";
// import Banner from "../../components/banner";
import ELogo from "../../assets/images/sailor/logo.svg";
import ELogo1 from "../../assets/images/sailor/sailoronboard.svg";
import Lbg from "../../assets/images/sailor/login-bg.svg";
// import { useContext } from "react";
// import NoteContext from "../../context/NoteContext";
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { config } from "../../util/apiconfig";
// import { navigate } from '@reach/router';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NoteContext from "../../context/NoteContext";
import EPCLogo from "../../assets/images/sailor/logo-color.png";

const schema = yup.object().shape({
	username: yup.string().required('Username is required'),
	password: yup
	.string()
	.required('Password is required')
	.min(8, 'Password must be at least 8 characters'),
});




function Login() {
	const obj = useContext(NoteContext)
	const [loggedIn, setLoggedIn] = useState(false);
	const { register, handleSubmit, formState: { errors } } = useForm({
		resolver: yupResolver(schema),
	});
	const navigate = useNavigate();

	const handleSignIn = async (formdata) => {
		console.log("login",formdata);
		let userData = {"Email" : formdata["username"] , "Password": formdata["password"] }

			let data = await fetch(config.mongo_endpoint+'api/v1/sailorUser/logIn', {
				method: "POST",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify(userData),
			});
			let res = await data.json();
			console.log(res);
			if (res.status === "Success") {
				await localStorage.setItem('Token',res.data[0].token);
				await localStorage.setItem('Name',res.data[0].name);
				await obj.setName(res.data[0].name);
				await localStorage.setItem('onBoardingID',res.data[0].onBoardingID);
				await localStorage.setItem('Type',res.data[0].Type);
				await localStorage.setItem('userId',res.data[0].userId);
				await localStorage.setItem('loggedIn', true);
				setLoggedIn(true);
				navigate("/Sailordashboard");

			} else {
				toast.error(res.message, {
					position: toast.POSITION.TOP_CENTER,
					theme: "colored",
				  });  
				

			}
		
	};




	return (
		<>

			<div className="login-section bg" style={{ backgroundImage: `url(${require("../../assets/images/sailor/login-bg.svg").default})` }}>
				<div className="row-2">
					<div className="login-white">
						<div className="login-lft">

							<div className="login-l">
								<div>
									<Link to="/">
										{/* <img src={ELogo} className="img-fluid light-logo" alt="" width="70" />
										<img src={ELogo1} className="img-fluid logo-txt ml-10" alt="" width="100" height="93" /> */}
										<img src={EPCLogo} className="img-fluid light-logo" alt="" width="150px"/>
									</Link>
									<h1><span>Welcome to </span> <br /> Sailor Onboarding</h1>

									<div className="btn-sct">
										<div className="btn-lnks">

											<div className="officer-lnk"><a href="Manninglogin">Manning Agent Login​</a></div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="login-rht">
							<div className="col-md-10 col-lg-10 col-xl-9 mx-auto">
								<div className="card-sigin">
									<div className="mb-2 d-flex">
									</div>
									<div className="card-sigin">
										<div className="main-signup-header">

											<h2>Sailor Login</h2>
											<form onSubmit={handleSubmit(handleSignIn)}>

												<div className="form-group">
													<label>Email address</label>
													<input className="form-control"
														placeholder="Enter your email" type="text" {...register('username')} />
													<span style={{color:'red', fontWeight:600}}>{errors.username?.message}</span>
												</div>
												<div className="form-group">
													<label>Password</label> <input className="form-control"
														placeholder="Enter your Password" type="password" {...register('password')} />
													<span style={{color:'red', fontWeight:600}}>{errors.password?.message}</span>
												</div>

												<button className="btn btn-main-primary btn-block" type="submit">Login</button>
											</form>
											<div className="main-signin-footer mt-3">												
													<p>Forgotten Password?
														<Link to="/SailorForgetPassword" className="resend ml-1">
													Reset It</Link>
													</p>
													<p>Didn't have an account ? <Link to="/signup" className="resend">
													Create Account</Link></p>
											</div>											
										</div>
									</div>
								</div>
							</div>
						</div>


						<ToastContainer />
					</div>

				</div>
			</div>





		</>
	);
}

export default Login;